import profileService from '@/services/queries/profileQueries'
import StylistsPermissionsTable from '../StylistsPermissionsTable.vue'

export default {
  components: {
    StylistsPermissionsTable
  },

  data() {
    return {
      formData: {
        credentials: {
          email: '',
          password: '',
          role: 'stylist'
        },
        profile: {
          full_name: '',
          nick_name: '',
          is_admin: false,
          capabilities: {
            is_checking_required: false,
            is_statistic_allowed: false,
            is_archetype_allowed: false,
            is_color_type_allowed: false,
            is_consulting_allowed: false,
            is_trending_allowed: false,
            max_order_count: 15,
          }
        }
      },

      showPassword: false,
      loading: false
    }
  },

  metaInfo() {
    return {
      title: this.$i18n.t('admin.stylists.new.pageTitle')
    }
  },

  methods: {
    createStylist() {
      this.loading = true

      let promises = []

      Object.values(this.$refs).forEach(ref => {
        promises.push(ref.validate())
      })

      Promise.all(promises).then(results => {
        let notValid = results.some(success => !success)

        if (notValid) {
          this.loading = false

          return
        }

        profileService.store(this.formData).then(() => {
          this.$router.push({ name: 'admin.stylists' })

          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      })
    },
  },
}